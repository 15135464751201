import React from "react";

import JujuyLogo from "assets/img/brand/jujuy/justicia-logo-jujuy.png";
import JujuyLogoLight from "assets/img/brand/jujuy/justicia-logo-jujuy-light.png";
import JujuyFav from "assets/img/brand/jujuy/jujuy_iso.png";
import DefaultJujuy from "assets/img/brand/jujuy/defaultJujuy.png";

import InterBannerWA from "assets/img/brand/subastas_electronicas/inter_banners/Inter_bannerWA.webp";


export const site = 'jujuy';

export const sources = {
    jujuy: {
        url: document.location.origin,
        logo: JujuyLogo,
        logolight: JujuyLogoLight,
        ws_path: `${document.location.origin}/wss`.replace('http', 'ws').replace('https', 'wss'),
        name: 'Subastas Judiciales Jujuy',
        navbar_class: 'navbar-jujuy',
        headroom_class: 'headroom-jujuy',
        favicon: JujuyFav,
        theme_color: '#516391',
        theme_name: 'jujuy',
        defaultImg: DefaultJujuy,
        interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

        title: 'Subastas Judiciales',
        recaptcha_key: '6LePixglAAAAAIsLswgpKMJ5j22NSLeULr5jk-oo',
        gtm_ID: 'GTM-N4CF3C7H',
        contact_data: {
            title: <>
                <h5>Ministerio Público de la Acusación</h5>
                <h5>Provincia de Jujuy</h5>
            </>,
            lines: [
                'El Eucalipto Esquina Los Tipales, B° Chijra',
                'San Salvador de Jujuy, Jujuy',
                'Teléfono: +54 9 (388)-4040223',
                'Email: subastas@mpajujuy.gob.ar',
            ],
        },
        support_email: 'soportempa@esubasta.ar'
    },
};